import React, { useEffect, useMemo, useState } from "react"
import {
  useHistory,
} from "react-router-dom";
import {
  getAccountTransaction,
  getAccInfo,
} from '../functions'
import { Col, Row, Spinner } from "react-bootstrap";
import Button from '@mui/material/Button';
import CardLoader from "../loaders/CardLoader";
import BrowseCard from '../components/BrowseCard'
const auctionStyle =
{
  "margin": "auto",
  "paddingBottom": "100px"
}
const headingStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const auctionTitleStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const Browse = (props) => {
  const history = useHistory()
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  document.title = "Auction - NFT Jam"
  const {
    stdlib,
    acc,
    approvalProgram,
    firstBlock,
    ADDR_PLATFORM,
    ADDR_DISCOVERY
  } = props
  const [app, setApp] = useState(null)
  const [apps, setApps] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [state, setState] = useState({})
  const getApps =
    useMemo(() => async (addr, block, program) => {
      let params = {
        "min-round": firstBlock,
        "tx-type": "pay"
      }
      let res = await getAccountTransaction(addr, params)
      let transactions = Array(...(res?.data?.transactions ?? []))
      let senders = transactions.map(el=>el?.sender)
      let accounts = (await Promise.all(senders.map(el => getAccInfo(el)))).map(el=>el?.data)
      //console.log({res, transactions, senders, accounts})
      // TODO: save min conirmed round as last block 
      return accounts.filter(el=>el.amount > 0).map(el=>el.address)
    }, [ADDR_DISCOVERY, firstBlock, approvalProgram])
  useEffect(() => {
    if (!apps) {
      (async () => {
        getApps(ADDR_DISCOVERY, firstBlock, approvalProgram)
          .then(apps => setTimeout(() => setApps(apps), 2000))
      })()
    }
  }, [])
  const handleGalleryClick = (id) => {
    history.push(`/auction/${id}`)
  }
  const buttons = <>
    <Button>LIVE</Button>
    {false && <Button disabled>CLOSED</Button>}
  </>
  const colProps = {
    xs: 12
  }
  if(apps && apps.length > 1) {
    colProps.sm = apps.length > 1 ? 6 : 12
  }
  if(apps && apps.length > 3) {
    colProps.md = apps.length > 3 ? 4 : 6
  }
  if(apps && apps.length > 4) {
    colProps.lg = apps.length > 4 ? 3 : 4
  }
  if(apps && apps.length > 5) {
    colProps.xl = apps.length > 5 ? 2 : 3
  }
  return <div id="browse" style={auctionStyle}>
    {apps ? <Row>
    {apps.map((el,i) => 
      <Col className="mt-4"
        {...colProps}
        style={{
      }}>
        <CardLoader addr={el}>
          <BrowseCard onClick={handleGalleryClick} />
        </CardLoader>
      </Col>
    )}
    </Row> : <div style={{
        ...auctionTitleStyle,
        "fontWeight": "100",
        "color": "white",
        "marginTop": "100px"
      }}>
        <Spinner
          as="span"
          animation="grow"
          size="lg"
          role="status"
          aria-hidden="true"
        />Loading Live Auctions...
      </div>}
  </div>
}

export default Browse