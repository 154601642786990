import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card from '../components/Card'

const stackStyle = {
  "marginTop": "32px"
}
const boxStyle = {
  "textAlign": "left"
}
const questionTypographyStyle = {
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "bold",
  "fontSize": "18px",
  "lineHeight": "21px"
}
const Faq = (props) => {
  const serviceName = "NFT Jam"
  document.title = `FAQ - ${serviceName}`
  const listingFee = '0.1 ALGO'
  const salePercentage = '1%'
  return <div id="faq" style={{ "paddingBottom": "100px" }}>
    <Card title="Faq" width={666}>
      <Stack style={stackStyle} spacing={6} justifyContent="center">
        {[
          {
            question: `What service does ${serviceName} offer?`,
            answer: `${serviceName} enables users to buy/sell Assets (Tokens/Currencies) through auctions.`
          },
          /*
          {
            question: `How do the auctions work for the seller?`,
            answer: `For sellers, they create the auction, and then deposit their NFT into an escrow account until the auction ends. Once the auction ends, if there are no bids or the minimum bid amount has not been exceeded, the seller will receive their NFT back. If there are bids and the minimum bid amound has been exceeded, the seller will receive the remainder of the winning bid after deductions.`
          },
          {
            question: `How do the auctions work for the buyer?`,
            answer: `For buyers, they can bid in any auction. When a buyer bids, the payment for that bid is held in escrow. If a higher bid occurs, then the previous winning bid is returned to the buyer. If the buyer's bid ends up winning the auction, then the buyer will receive the NFT at the end of the auction.`
          },
          */
          {
            question: `How much does it charge?`,
            answer: `
            We charge a listing fee (${listingFee}) per auction, and a sale percentage (${salePercentage}) per auction.
            For example, if an auction is created to sell 1 ZET the following cost will occur: 
            (1) .3 ALGO + 1 ZET to deploy contract.
            In in the case that the auction was setup with a royalty % of 0, a reserve price of 1,
            and 1 bid is placed for 2 ALGO, the following outcome would occur:
            (1) auctioneer would receive 1.98 ALGO from sale;
            (2) bidder would receive 1 ZET.
            `
          },
          /*
          {
            question: `Why is there a listing fee?`,
            answer: `The listing fee covers the cost of deploying the auction smart contract, as well as making live auctions disoverabled.`
          },
          {
            question: `Are there any royalties that the buyer/seller pay when a NFT is won?`,
            answer: `When creating an auction the seller decides the royalty % that will apply if ownership transfer takes place at the end of the auction. The seller may also opt-out of royalties. For sellers, royalties are deducted from the highest bid on sale.`
          },
          {
            question: `How is the minimum bid calculated?`,
            answer: `The minimum bid is the start bid if no bids have been made or the last bid + bid increment. The bid increment is set by the seller on auction creation.`,
          },
          {
            question: `How are your auctions hosted? Are they occurring on your server or on the blockchain?`,
            answer: `${serviceName} is 100% decentralized, that is auctions exist solely on the blockchain as smart contracts created buy the seller.`,
          },
          {
            question: `Why do I need to send my NFT to start an auction?`,
            answer: `To guarenttee that a potential winning bid may receive an NFT at the end of an auction, sellers must deposit their NFT to start accepting bids.`
          },
          {
            question: `Why do I need to send my bid to some other account?`,
            answer: `To guarenttee that the seller may recieve your bid if it is the highest bid at the end of the auction, buyers must deposit their bid amount in order to be accepted as a bid.`,
          },
          {
            question: `How many auctions can I bid on?`,
            answer: `As many as you want.`,
          },
          {
            question: `How many auctions can I list?`,
            answer: `As many as you want. However, created app limits may apply despending on the network.`
          },
          //{ question: "What is Jam Coin?", answer: "Answer for the question will be here. t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English." },
          //{ question: "Who sets the auction start bid?", answer: "Answer for the question will be here. t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English." },
          //{ question: "What is the auction reserve bid?", answer: "Answer for the question will be here. t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English." },
          {
            question: "Do auctions created on older version work with newer versions?",
            answer: <p>
              Currently, auctions created using older versions are not guaranteed to work with newer version.
              An older version of the app may be provided to allow closing of auction belonging to a older version.
            </p>
          },
          */
          {
            question: "What is the current version of NFT Jam?",
            answer: <p>
              The current version of {serviceName} is 0.1.6r6.
            </p>
          },
        ].map(({ question, answer }) =>
              <Box style={boxStyle}>
                <h2 style={questionTypographyStyle}>{question}</h2>
                <p>{answer}</p>
              </Box>)}
      </Stack>
    </Card>
  </div>
}
export default Faq
