const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const CloseButton = ({
  variant,
  onClick,
}) => {
  let label
  let style = buttonStyle
  switch (variant) {
    case 'claim':
      label = "Claim NFT"
      style = {
        ...style,
        "background": "linear-gradient(111.85deg, rgb(255 193 7 / 68%) -23.82%, #ffc107 119.4%)",
        "boxShadow": "#ffc10736 0px 10px 20px"
      }
      break
    case 'close':
    default:
      label = "Close Auction"
      style = {
        ...style,
        "background": "linear-gradient(111.85deg, #dc3545b0 -23.82%, #dc3545 119.4%)",
        "boxShadow": "#dc354552 0px 10px 20px"
      }
      break
  }
  return <div
    role="button"
    style={style}
    onClick={onClick}>
    {label}
  </div>
}
export default CloseButton;