import { useHistory } from "react-router"

const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const JoinButton = () => {
	let history = useHistory()
	return <div
	  role="button"
	  style={buttonStyle}
	  onClick={() => {
	    localStorage.setItem("walletFallback", "MyAlgoConnect")
	    history.go()
	  }}>
	  Join Auction
	</div>
      }
export default JoinButton;