import React, { useEffect, useState } from "react"
import {
  getAccInfo,
  getAsset2,
  getAssetImage,
} from "../functions";
const CardLoader = ({
  addr,
  children,
}) => {
  const [state, setState] = useState(null)
  useEffect(() => {
    Promise.all([
      getAccInfo(addr)
    ])
      .then(async ([
        acc 
      ]) => {
        console.log({acc})
        let { data } = acc
        let amount = (((data?.amount ?? 200000) - 200000) / 1000000).toFixed(2)
        let asset = data?.assets[0]
        let image
        let assetId
        let assetInfo
        if(asset) {
          assetId = asset['asset-id']
          assetInfo = (await getAsset2(assetId))?.data
          image = await getAssetImage(assetId)
        }
        let status = data?.amount > 0 ? "live" : "closed"
        setState({
          amount: Math.floor(amount),
          addr,
          status,
          asset: {
            ...asset,
            ...assetInfo
          },
          image,
        })
      })
  }, [])
  return (
    React.Children.map(children, (child, i) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child,
          { ...state, key: addr });
      }
      return child;
    })
  )
}
export default CardLoader