import * as backend from '../../build/index.main.mjs'
const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #dc3545b0 -23.82%, #dc3545 119.4%)",
  "boxShadow": "#dc354552 0px 10px 20px",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const DeleteButton = ({
  ctc,
  closed,
}) => {
  return closed && <div
    role="button"
    style={buttonStyle}
    onClick={() => backend.Relay(ctc, { close: () => { } })}>
    Delete Auction
  </div>
}
export default DeleteButton