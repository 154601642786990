import React, { useEffect, useState } from "react"
import {
  formatCurrencyFromSome,
} from '../functions'
const IndicatorLoader = ({
  id,
  stdlib,
  children,
  v,
  initialState,
}) => {
  const [state, setState] = useState(initialState)
  console.log({
    id,
    stdlib,
    children,
    v,
    initialState,
  })
  useEffect(() => {
    if(state.isReservePriceMet) return
    let interval = setInterval(() => {
      Promise.all([
        v.currentPrice(),
      ])
        .then(async ([
          cp,
        ]) => {
          let fCp = formatCurrencyFromSome(stdlib, cp)
          let isReservePriceMet = parseFloat(state.reservePrice) < parseFloat(fCp)
          if (isReservePriceMet) {
            setState({ ...state, isReservePriceMet: true })
            clearInterval(interval)
            return
          }
        })
    }, 4000);
    return () => clearInterval(interval)
  }, [])
  return (
    React.Children.map(children, (child, i) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child,
          { ...state, key: id });
      }
      return child;
    })
  )
}
export default IndicatorLoader;