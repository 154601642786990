import { Card, Image, Placeholder } from "react-bootstrap"
import Icon from "react-crypto-icons"
import { formatCompactAddress } from "../functions"

const BrowseCard = ({
  addr,
  asset,
  status,
  onClick,
  image,
  amount,
}) => {
  return status === "live" ?
    <>
      <Card
        onClick={() => onClick(addr)}
        style={{
          borderRadius: "20px",
          overflow: "hidden",
          border: "0",
        }}>
        <Card.Img src={image} alt={asset?.assetname} />
      </Card>
      <div className="mt-1">
        {asset?.assetname}&nbsp;
        {amount > 0 && <span><Icon style={{
          verticalAlign: "baseline"
        }} size={13} name="algo" />&nbsp;{amount}</span>}
      </div>
    </> : ""
}
export default BrowseCard