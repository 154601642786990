import { FeatureFlagContext } from "../context/FeatureFlagContext";
import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  useParams,
  useHistory
} from "react-router-dom";
import {
  getAccountInfo,
  getAppInfo,
  getAccountTransaction,
  getBlock,
} from '../functions'
import { useSnackbar } from 'notistack';
import AuctionLoader from '../loaders/AuctionLoader';
import ContractLoader from "../loaders/ContractLoader";
import AuctionView from '../views/AuctionView'
import Card from '../components/Card'
import { Image, Row } from "react-bootstrap";
import GalleryCard from "../components/GalleryCard";
import styles from './auction.module.css'
import md5 from "blueimp-md5";

const Auction = (props) => {
  const history = useHistory()
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  document.title = "Auction - NFT Jam"
  const {
    stdlib,
    acc,
    approvalProgram,
    firstBlock,
    ADDR_PLATFORM,
    ADDR_DISCOVERY,
  } = props
  let { appId } = useParams()
  const isAlgoAddress = appId
    && WAValidator.validate(appId, 'algo')
  const isAppId = !isAlgoAddress
    && /^[0-9]+$/.test(appId)
  const [apps, setApps] = useState(isAppId ? [appId] : [])
  const [state, setState] = useState({
    ctc: false,
    view: null,
    navigation: Boolean(appId) && !isNaN(parseInt(appId)) && !String(appId).match(/[^0-9]/),
    caughtUp: true,
    joined: true,
    once: true,
    bids: []
  })

  console.log({ appId, isAlgoAddress, isAppId })
  // EFFECT
  useEffect(() => {
    (async () => {
      if (isAppId) {
        setApps([appId])
      }
      else if (isAlgoAddress) {
        let addr = appId
        let ids = (await Promise.all([
          (async addr => {
            let tx = ((await getAccountTransaction(addr))?.data?.transactions ?? []).pop()
            let round = (tx ?? {})['confirmed-round'] ?? -1
            let sender = (tx ?? {})['sender'] ?? ""
            let res = ((((await getBlock(round))?.data?.transactions ?? []).filter(el => el['tx-type'] === 'appl' && el['sender'] === sender)[0] ?? {})['application-transaction'] ?? {})['application-id'] ?? -1
            return res > 0 ? [res] : []
          }),
          (async addr => {
            let accInfo = await getAccountInfo(addr)
            let apps = accInfo['created-apps']
              .filter(app => approvalProgram === md5(app?.params['approval-program']))
              .map(({ id }) => id)
            return apps
          })
        ].map(el => el(addr)))).flatMap(el => el)
        if (ids.length > 0) {
          history.push(`/auction/${addr}`)
          setApps(null)
          setApps(ids)
        }
      }
    })()
  }, [])

  /*
   * handleKeyPress
   * - handles input from auction search
   */
  const handleKeyPress = async (event) => {
    let { key, target } = event
    let { value } = target
    const isAlgoAddress =
      WAValidator.validate(value, 'algo')
    const isAppId = !isAlgoAddress
      && /^[0-9]+$/.test(value)
    if (isAlgoAddress) {
      let addr = value
      let ids = (await Promise.all([
        (async addr => {
          let tx = ((await getAccountTransaction(addr))?.data?.transactions ?? []).pop()
          let round = (tx ?? {})['confirmed-round'] ?? -1
          let sender = (tx ?? {})['sender'] ?? ""
          let res = ((((await getBlock(round))?.data?.transactions ?? []).filter(el => el['tx-type'] === 'appl' && el['sender'] === sender)[0] ?? {})['application-transaction'] ?? {})['application-id'] ?? -1
          return res > 0 ? [res] : []
        }),
        (async addr => {
          let accInfo = await getAccountInfo(addr)
          let apps = accInfo['created-apps']
            .filter(app => approvalProgram === md5(app?.params['approval-program']))
            .map(({ id }) => id)
          return apps
        })
      ].map(el => el(addr)))).flatMap(el => el)
      if (ids.length > 0) {
        history.push(`/auction/${addr}`)
        setApps(null)
        setApps(ids)
      }
    }
    else if (isAppId) {
      let appId = value
      //let candidatePath = `/auction/${appId}`
      if (key === 'Enter') { //&& location.pathname !== candidatePath) {
        try {
          await getAppInfo(appId)
          history.push(`/auction/${appId}`)
          setApps(null)
          setApps([appId])
        } catch (e) {
          alert(`Auction ${appId} not found`)
        }
      }
    }
  }

  const searchBarStyle = {
    /*"width": "540px",*/
    "height": "60px",
    "background": "#FFFFFF",
    "border": "1px solid #C9C9C9",
    "boxSizing": "border-box",
    "boxShadow": "0px 8px 28px rgba(0, 0, 0, 0.10)",
    "borderRadius": "52px",
    "display": "flex"
  }
  const searchCardStyle =
  {
    "height": "277px",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "margin": "auto"
  }
  const compactAuctionSearchCardStyle =
  {
    ...searchCardStyle,
    "width": "90vw",
    "padding": "50px 30px",
  }
  const auctionSearchCardStyle =
  {
    ...searchCardStyle,
    "width": "640px",
    "padding": "50px",
  }
  const auctionTitleStyle =
  {
    "height": "38px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase",
    "color": "#2A3035",
    "opacity": "0.8",
  }
  const auctionDescriptionStyle = {
    "display": "none"
  }
  const auctionSearchContainerStyle =
  {
    "marginTop": "79px",
  }
  const auctionSearchbarInputStyle =
  {
    "width": "85%",
    "border": "0",
    "marginLeft": "15px",
    "borderRadius": "52px"
  }
  const auctionSearchbarInputTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "fontSize": "14px",
    "lineHeight": "17px",
    "letterSpacing": "0.05em",
    "color": "#484959"
  }
  const auctionStyle =
  {
    "margin": "auto",
    "paddingBottom": "100px"
  }
  const navbarBrandTypographyStyle =
  {
    "color": "black",
    "paddingTop": "8px",
    "paddingBottom": "8px",
    "fontFamily": "Roboto",
    "fontSize": "12px",
    "fontStyle": "normal",
    "fontWeight": "600",
    "lineHeight": "14px",
    "letterSpacing": "0em",
    "textAlign": "left"
  }
  const myAuctionStyle =
  {
    "width": "100px",
    "height": "14px",
    "fontFamily": "Roboto",
    "fontStyle": "normal",
    "fontWeight": "500",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "rgb(85, 89, 93)"
  }
  return <FeatureFlagContext.Consumer>
    {({
      myAuctions
    }) => <div id="auction" style={auctionStyle}>
        {[
          {
            style: auctionSearchCardStyle,
            className: "d-none d-sm-block"
          },
          {
            style: compactAuctionSearchCardStyle,
            className: "d-xs-d-block d-sm-none"
          }
        ].map(props =>
          <div {...props}>
            <div style={auctionTitleStyle}>Auction</div>
            <div style={auctionDescriptionStyle}>&nbsp;</div>
            <div className="search" style={{ ...searchBarStyle, ...auctionSearchContainerStyle }}>
              <input
                className="search-bar"
                style={{ ...auctionSearchbarInputStyle, ...auctionSearchbarInputTypographyStyle }}
                placeholder="Search auction: Enter NFT Auction App Id"
                //defaultValue={appId} 
                onKeyPress={handleKeyPress} />
            </div>
          </div>)
        }
        {myAuctions && acc?.appInfo && acc?.appInfo.length > 0 && <div style={{
          "margin": "42px auto",
          "columnGap": "5vw",
          "display": "flex",
          "alignItems": "center",
          "padding": "10px",
          "borderRadius": "10px",
          "background": "rgba(0, 0, 0, 0) linear-gradient(106.65deg, rgb(177, 255, 130) -6.26%, rgb(62, 249, 170) 124.55%) repeat scroll 0% 0%",
        }}>
          <div style={navbarBrandTypographyStyle}>My Auctions</div>
          <div style={{
            "columnGap": "1vw",
            "display": "flex",
            "rowGap": "10px",
            "flexWrap": "wrap",
            "justifyContent": "center"
          }}>
            {acc && acc.appInfo.map(el =>
              <span
                role="button"
                onClick={() => {
                  window.location = `/auction/${el.id}`
                  // TODO: bring this back
                  /*
                  history.push(`/auction/${appId}`)
                  setState({
                    view: null,
                    ctc: false,
                    once: false,
                    navigation: true
                  })
                  */
                }}
                style={myAuctionStyle}>
                {el.id == appId
                  ? (<span style={{ "fontWeight": "900", "color": "rgb(199, 90, 243)" }}>{el.id}</span>)
                  : (<span className="my-auction-list-item" >{el.id}</span>)}
              </span>)}
          </div>
        </div>}
        {acc && apps && apps.map((id, index) =>
          <ContractLoader acc={acc} id={id} stdlib={stdlib} approvalProgram={approvalProgram} firstBlock={firstBlock}>
            <AuctionLoader platformAddr={ADDR_PLATFORM}>
              <AuctionView acc={props.acc} id={appId} {...state.view} v={state.v} apis={state.ctc.apis} loading={state.loading} closed={state.closed} joined={state.joined} closing={state.closing} address={props?.acc?.address} caughtUp={state.caughtUp} showJoin={props.ADDR_MNEMONIC === props?.acc?.address} />
            </AuctionLoader>
          </ContractLoader>)}
      </div>}
  </FeatureFlagContext.Consumer>
}

export default Auction;